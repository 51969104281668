.logo img {
  height: 90px;
}

.logo {
  margin: 0px 15px;
  margin-top: 25px;
}

.logo-footer img {
  height: 60px;
}

.logo-footer {
  margin: 0px 15px;
  margin-top: 10px;
}

.hero-light {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 75vh;
  padding: 0px 7vw;

  background-image: url('assets/backdrop-light.png');
  background-size: cover;
  background-position: center bottom;
}

.hero-title {
  font-family: 'Futura', sans-serif;
  color: black;
  font-size: 112px;
  font-weight: 700;
  text-align: center;
  margin: 0px 15px;
  margin-top: 20px;

}

.hero-subtitle {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  text-align: center;
  margin-bottom: 15px
}

.hero-text {
  font-family: 'Avenir Next', sans-serif;
  color: black;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  padding: 5px 10px;
  text-align: left;

  position: relative;
  padding-left: 30px;
  /* Adjust this for your bullet spacing */
}

.hero-text::before {
  content: '';
  position: absolute;
  left: 0;
  background-image: url('/src/assets/jfct-bullet-2.png');
  background-size: contain;
  /* Ensure the image fits within the specified width and height */
  background-repeat: no-repeat;
  width: 0.6rem;
  /* Adjust size as needed */
  height: 0.6rem;
  /* Adjust size as needed */
  line-height: 1;
  /* Ensure it aligns with your text */
  top: 12px;
  /* Adjust to vertically align with text */
}




.hero-blue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 600px;

  background-image: url('assets/backdrop-blue.png');
  background-size: contain;
  background-position: center bottom;
}


.hero-dark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 600px;

  background-image: url('assets/backdrop-dark.png');
  background-size: contain;
  background-position: center bottom;
}

.divider {
  border: none;
  height: 1.0px;
  width: 100%;
  max-width: 2100px;
  margin-top: 10px;
  margin-bottom: 50px;
  background-color: gray;
}

.box {
  padding: 40px 30px;
  border-radius: 10px;
  border: 0.5px solid gray;
  margin-bottom: 60px;
  background-color: rgba(255, 255, 255, 0.35);
  /* 80% opacity */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.35);
  width: 100%;
  max-width: 1000px;
  box-sizing: border-box;
  margin-left: 20px;
  margin-right: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0px 50px;
  height: 100%;
}

.footer-item {
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* margin: 30px 100px; */
  margin: 30px 0px;
  min-width: 300px;
  height: 100%;
}



.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0px;

  /* height: 300px; */
  min-height: 25vh;

  /* background-color: rgb(19, 19, 20); */
  background-image: url('assets/backdrop-dark.png');
  background-size: contain;
  background-position: center bottom;
}

.footer-title {
  font-family: 'Futura', sans-serif;
  color: white;
  font-size: 64px;
  font-weight: 600;
  text-align: center;
}

.footer-text {
  font-family: 'Avenir Next', sans-serif;
  color: white;
  font-size: 18px;
  font-weight: 200;
  text-align: left;
}

.footer-text-bold {
  font-family: 'Avenir Next', sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}

.button {
  color: white;
  font-family: 'Avenir Next', sans-serif;
  font-size: 16px;
  font-weight: 500;

  margin-top: 10px;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: black;

  transition: background-color 0.3s ease;
  cursor: pointer;

  text-align: center;
  min-width: 150px;
}

.gap {
  min-width: 30px;
}

.button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.mail-to {
  text-decoration: none;
}

.row-col {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}


@media (max-width: 1200px) {
  .row-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .hero-title {
    font-size: 84px;
  }

  .logo img {
    height: 70px;
  }

  .logo {
    margin: 0px 10px;
    margin-top: 20px;
  }

  .hero-title {
    margin: 0px 10px;
    margin-top: 20px;
  }

  

}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}